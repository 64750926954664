
@import './stemdoerDetail.scss';

.theme--light.v-chip:not(.v-chip--active) {
  color: #606061 !important;
  background-color: #ececef !important;
}

.v-tooltip__content {
  background-color: rgb(0, 0, 0);
  opacity: 1;
  color: #ffffff;
  border-radius: 4px;
  max-height: fit-content;
}

::v-deep .v-timeline {
  padding-top: 8px !important;
}

.v-timeline:before {
  height: calc(100% - 155px);
  color: #ececef !important;
  top: 10px;
  margin-right: 0;
  margin-left: 0;
  left: 2px !important;
}

::v-deep .v-timeline-item__dot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  height: 6px !important;
  width: 6px !important;
}

::v-deep .v-timeline-item__divider {
  height: fit-content !important;
  width: fit-content !important;
  color: #ececef !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
}

::v-deep .v-timeline-item {
  display: -webkit-box !important;
}

::v-deep .v-timeline-item__body {
  margin-left: 22px !important;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3) !important;
  opacity: 1;
  color: var(--v-btnPrimaryText-base) !important;
  border-radius: 4px;
}

.tooltip-bottom::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  bottom: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-bottom.background-darken3::before {
  border-bottom: solid 6px var(--v-background-darken3) !important;
}

::v-deep .overlay-text {
  font-size: 48px !important;
}
