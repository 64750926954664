<template>
  <v-pagination class="pagination" v-model="page" :length="totalPages" :total-visible="6" @input="onChange" rounded="circle" />
</template>

<script lang="ts" src="./sPagination.ts"></script>
<style scoped>
::v-deep .v-pagination .button {
  color: var(--v-text-base) !important;
  list-style: none;
  max-width: 28px;
}

::v-deep .v-pagination .v-icon {
  display: none !important;
}

::v-deep .v-pagination li:first-child {
  margin-right: 8px !important;
  background: url('./../../../../content/svgs/chevron-left.svg') no-repeat center center;
}

::v-deep .v-pagination li:last-child {
  margin-left: 8px !important;
  background: url('./../../../../content/svgs/chevron-right.svg') no-repeat center center;
}

::v-deep .v-pagination__item,
::v-deep .v-pagination__more,
::v-deep .v-pagination__navigation {
  color: var(--v-text-base) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
  text-align: center !important;
  border-radius: 50% !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-sizing: border-box !important;
  transition: background-color 0.3s !important;
}

::v-deep .v-pagination__item {
  min-width: 28px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 2px !important;
}

::v-deep .theme--light.v-pagination .v-pagination__item--active {
  background-color: transparent !important;
  color: var(--v-btnPrimary-base) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  margin: 0 2px !important;
}

::v-deep .v-pagination__more {
  background: url('./../../../../content/svgs/more.svg') no-repeat center center;
  background-size: contain;
  color: transparent !important;
  margin: 0 4px !important;
}

::v-deep .v-pagination__navigation {
  border: 1px solid var(--v-border-darken1) !important;
  margin: 0px !important;
}

::v-deep .v-pagination__item:hover {
  background-color: var(--v-background-darken2) !important;
}
</style>
