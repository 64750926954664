


.filter-container {
  background: var(--v-background-darken1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid var(--v-border-base) !important;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px !important;
}
