<template>
  <v-select
    @change="changeLanguage"
    v-model="currentLanguage"
    :items="languages"
    item-value="id"
    item-text="desc"
    class="custom-v-select"
    flat
    dense
    solo
  >
    <template v-slot:selection="{ item }">
      <div class="mr-2"><v-img :maxWidth="24" :src="'/content/svgs/' + item.svg + '.svg'"></v-img></div>
      <div class="text--text text--lighten-1 font-weight-medium">{{ item.desc }}</div>
    </template>
    <template v-slot:item="{ item }">
      <div class="mr-2"><v-img :maxWidth="24" :src="'/content/svgs/' + item.svg + '.svg'"></v-img></div>
      <div class="text--text text--lighten-1 font-weight-medium">{{ item.desc }}</div>
    </template>
  </v-select>
</template>

<script lang="ts" src="./sSelectLang.ts" />

<style scoped>
.custom-v-select >>> .v-select__selections input {
  cursor: pointer;
}
.custom-v-select >>> .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
}
.custom-v-select >>> .v-text-field__details {
  display: none;
}
</style>
