
.language-icon {
  margin-left: 8px;
  border: 1px solid #dbdbe0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
