#stemdoerDetail {
  height: max-content;
  width: 100%;
  border-radius-top: 2px;

  h5 {
    color: #222226;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .row {
    width: 100% !important;
  }

  .firstBox {
    border: 1px solid #dbdbe074;
    border-radius: 3px;
    width: 100%;
  }

  a:hover {
    color: inherit;
  }

  .secondBox {
    border-radius: 3px;
    width: 100%;
  }

  .experienceBox {
    border: 1px solid #dbdbe074;
    margin: 2em;
    border-radius: 3px;
    width: 100%;
  }

  .educationBox {
    border: 1px solid #dbdbe074;
    margin: 2em;
    border-radius: 3px;
    width: 100%;
  }

  .exp-container {
    width: 100%;
    margin-left: 0em;
  }

  .header-content {
    color: #222226;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
    width: fit-content;
  }

  .position {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  .company {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }

  .date {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }

  .description {
    color: #606061;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
    margin-left: -2px;
  }

  .grade {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  .btnPDF {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    transition: 0.6s;
    margin-left: 6px;
    box-shadow: none;

    .iconPDF {
      transform: scale(0.9);
      transition: 0.3s;
    }
  }

  .btnPDF:hover .iconPDF {
    transform: scale(0.9);
    transition: 0.3s;
  }

  .pdf {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  .verfication-mark {
    margin-top: -1.7em;
    margin-left: 0.7em;
  }

  .toolBoxTop {
    transform: scale(1.1);

    span {
      margin-left: -0.5em;
    }
  }

  .title-position {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
    margin-left: 0% !important;
    margin-bottom: 0% !important;
    padding: 0%;
  }

  .total-time {
    color: #606061;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
}
