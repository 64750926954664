<template>
  <v-select
    data-cy="sselector"
    :label="placeholder"
    :items="items"
    v-model="selectedItem"
    item-value="id"
    item-text="desc"
    class="custom-v-selector"
    return-object
    @change="handleChange"
    dense
    flat
    solo
    :menu-props="{ offsetY: true }"
  >
    <template v-slot:selection="{ index }">
      <span class="pt-1 pb-1 text-body-1 text--text" data-cy="sselector-selection-label">
        {{ labelDisplay }}
      </span>
    </template>

    <template v-slot:item="{ item }">
      <div
        data-cy="sselector-item"
        :class="[
          'text--text',
          'text--base',
          { 'selected-item': isItemSelected(item) },
          { 'text-body-1-bold': isItemSelected(item) },
          { 'text-body-1': !isItemSelected(item) }
        ]"
      >
        {{ item.desc }}
      </div>
    </template>
  </v-select>
</template>

<script lang="ts" src="./sSelector.ts"></script>

<style scoped>
.custom-v-selector ::v-deep .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 4px;
  height: 45px;
  min-width: 90px;
  color: var(--v-text-base) !important;
  margin-bottom: 0px !important;
  background: var(--v-background-base) !important;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item {
  color: var(--v-text-base) !important;
}

::v-deep input::placeholder {
  color: var(--v-text-lighten1) !important;
}

.v-input--is-focused ::v-deep .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-selector ::v-deep .v-text-field__details {
  display: none;
}

.custom-v-selector ::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-selector ::v-deep .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-selector ::v-deep .v-list-item__title .v-list-item__content {
  font-size: 30px;
}
</style>
