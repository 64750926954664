<template>
  <v-select
    data-cy="sselector-multiple"
    :label="placeholder"
    :items="items"
    v-model="selectedItems"
    item-value="id"
    item-text="desc"
    class="custom-v-selector"
    return-object
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    dense
    multiple
    persistent-hint
    flat
    solo
    :menu-props="{ offsetY: true }"
  >
    <!-- Slot para mostrar el label cuando hay algo seleccionado -->
    <template v-slot:selection="{ index }">
      <span class="pt-1 pb-1 text-body-1-medium text--lighten1" v-if="index === 0" data-cy="sselector-multiple-selection-label">
        {{ labelDisplay }}
      </span>
    </template>

    <!-- Slot para renderizar los ítems del menú desplegable -->
    <template v-slot:item="{ item }">
      <div
        data-cy="sselector-multiple-item"
        :class="[
          'text--text',
          'text--base',
          'select-container',
          { 'selected-item': isItemSelected(item) },
          { 'text-body-1-bold': isItemSelected(item) },
          { 'text-body-1-bold': isAllSelected },
          { 'text-body-1': !isItemSelected(item) }
        ]"
      >
        <span data-cy="sselector-multiple-item-text">{{ item.desc }}</span>
        <font-awesome-icon
          v-if="isItemSelected(item) || isAllSelected"
          class="ml-2 mr-1"
          style="font-size: 18px"
          :icon="['fas', 'check']"
          data-cy="sselector-multiple-item-check-icon"
        />
      </div>
    </template>
  </v-select>
</template>

<script lang="ts" src="./sSelectorMultiple.ts" />

<style scoped>
.custom-v-selector ::v-deep .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 4px;
  height: 45px;
  min-width: 90px;
  color: var(--v-text-base) !important;
  margin-bottom: 0px !important;
  background: var(--v-background-base) !important;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item {
  color: var(--v-text-base) !important;
}

::v-deep .v-select__selections,
::v-deep input::placeholder {
  color: var(--v-text-lighten1) !important;
}

.v-input--is-focused ::v-deep .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-selector ::v-deep .v-text-field__details {
  display: none;
}

.custom-v-selector ::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-selector ::v-deep .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-selector ::v-deep .v-list-item__title .v-list-item__content {
  font-size: 30px;
}

.select-container {
  min-width: 195px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
