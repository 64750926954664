#checkout-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 206px;

  .title-span {
    color: $blackStemdo;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 3px;
  }
}
