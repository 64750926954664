
@import './checkout.scss';

.iconPDF {
  filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);
}

.box {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  border: 1px var(--v-border-base) solid;
}

::v-deep .modal-open[style] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

::v-deep .input-form-stemdo {
  background-color: white;
  border: 1px solid $lightGreyStemdo;
  display: block;
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: $blackStemdo;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 10px 0px 24px 0px;

  &::placeholder {
    color: $greyStemdo;
  }
}

::v-deep .input-form-stemdo:focus {
  color: $blackStemdo;
  outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);

  &::placeholder {
    color: $greyStemdo;
  }
}

::v-deep .modal-header {
  border-bottom: none;
  padding-top: 32px;
  padding-bottom: 0px;
  color: $blackStemdo;

  span {
    color: var(--v-text-base);
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
    padding: 16px 0px 0px 16px;
  }
}

::v-deep .close {
  display: flex;
  height: 28px;
  width: 28px;
  border: 1px solid var(--v-border-darken1) !important;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-right: 0px !important;
  font-size: 20px;
  color: var(--v-text-lighten1) !important;
  opacity: 1 !important;
}

::v-deep .close:hover {
  background-color: var(--v-text-lighten4) !important;
  opacity: 1 !important;
}

::v-deep .checkout-modal {
  max-width: 500px;
  width: 100%;
}

::v-deep .modal-span {
  color: var(--v-text-lighten1) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

::v-deep .modal-finish {
  color: var(--v-text-lighten1) !important;
  font-size: 16px !important;
  color: #222226 !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

::v-deep .v-text-field .v-input__slot {
  cursor: pointer;
  background-color: white !important;
  border: 1px solid $lightGreyStemdo !important;
  border-color: $lightGreyStemdo !important;
  min-height: 45px !important;

  input {
    cursor: pointer;
  }
}

::v-deep .v-input__prepend-inner {
  max-width: 24px;
  margin: 11px 12px 11px 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

::v-deep .v-input__append-inner {
  max-width: 24px;
  margin: 11px 12px 11px 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

::v-deep .modal-open {
  padding-right: 0px !important;
  overflow-y: scroll;
}

::v-deep .modal-content {
  border: 0px !important;
}

::v-deep .modal-dialog {
  padding-top: 12px !important;
  max-height: 100%;
}

::v-deep .modal {
  align-content: center;
}

::v-deep .v-date-picker-table .v-btn {
  border-radius: 4px;
  color: var(--Body, var(--Colors-Body, var(--v-text-base)));
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

::v-deep .v-btn {
  text-transform: unset !important;
}

::v-deep .v-btn.v-btn--active.accent {
  color: white;
  background-color: #4237ff !important;
}

::v-deep .v-input__slot {
  border: 1px solid $lightGreyStemdo;
  border-radius: 4px;
  flex-shrink: 0;
  margin: 10px 0px 24px 0px;
}

::v-deep .v-text-field--outlined fieldset {
  border: none !important;
}

::v-deep .v.menu {
  top: 355px !important;
  left: 207px !important;
  transform-origin: center !important;
}

::v-deep .no-hover .v-input__slot:hover {
  border: 1px solid $lightGreyStemdo;
  background-color: inherit !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

::v-deep .no-hover .v-input__slot:hover:focus {
  color: $blackStemdo;
  outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);

  &::placeholder {
    color: $greyStemdo;
  }
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .modal-footer {
  display: none;
}

::v-deep .v-application .accent {
  background-color: $blueStemdo !important;
}

::v-deep .date-field .v-input__icon--prepend-inner {
  margin-right: 12px;
}

::v-deep .input-form-stemdo:focus {
  color: $blackStemdo;
  outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);

  &::placeholder {
    color: $greyStemdo;
  }
}

::v-deep .v-dialog--active {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  margin: 0px !important;
  position: fixed !important;
  top: 0% !important;
  right: 0% !important;
  background-color: white;
}

.clear-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.clear-end-date-btn {
  z-index: 1;
  font-size: 12px;
  color: $blueStemdo !important;
  margin: 8px 16px 0px 0px;
  font-weight: 400;
  word-wrap: break-word;
  height: auto;
  min-width: auto;
}

.clear-end-date-btn:hover {
  font-weight: 500;
  transition: all 0.1s ease-in-out;
}

.end-date-option {
  font-size: 12px;
  color: $blueStemdo !important;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: underline;
  margin: -6px 0px 12px 0px;
  padding: 3px;
  text-align: left;
}

.end-date-option {
  font-size: 12px;
  color: $blueStemdo !important;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: underline;
  margin: -6px 0px 12px 0px;
  text-align: left;
}

.end-date-option span:hover {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  font-weight: 500;
}

::v-deep .overlay-text {
  font-size: 24px;
}
