
.candidate-table {
  box-shadow: none !important;
}

.team-status {
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    background-color: #fde7e8;
    border-radius: 4px;
  }
}

::v-deep .v-data-table__wrapper table {
  border-collapse: separate !important;
}

::v-deep .v-data-table__wrapper table thead {
  th {
    background-color: var(--v-background-darken1) !important;
    border-bottom: none !important;
    color: var(--v-text-lighten1) !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 10px !important;
    height: 44px !important;
    min-width: 150px !important;
  }

  th:first-child {
    border-left: 1px solid var(--v-border-base) !important;
    padding-right: 8px !important;
    min-width: 0px !important;
    padding-left: 59px;
  }

  th:last-child {
    border-right: 1px solid var(--v-border-base) !important;
  }
}

::v-deep .v-data-table__wrapper table tbody {
  tr:hover {
    background-color: #f9f9f9 !important;
    cursor: pointer;
  }

  tr:active {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td {
    height: 53px !important;
    font-size: 16px !important;
    font-weight: 400;
    border-bottom: 1px solid var(--v-border-base) !important;

    &:first-child {
      border-left: 1px solid var(--v-border-base) !important;
      padding-right: 8px !important;
    }

    &:last-child {
      border-right: 1px solid var(--v-border-base) !important;
    }
  }

  tr:first-of-type {
    td {
      border-top: 1px solid var(--v-border-base) !important;
    }

    td:first-child {
      border-top-left-radius: 0px;
    }

    td:last-child {
      border-top-right-radius: 0px;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid var(--v-border-base) !important;
    }

    td:first-child {
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
}

::v-deep .remove-box-shadow {
  box-shadow: none !important;
}

::v-deep .overlay-text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.candidate-table ::v-deep .v-icon.mdi-checkbox-blank-outline.theme--light {
  color: var(--v-text-lighten2) !important;
}

.candidate-table ::v-deep .v-input--selection-controls__ripple {
  color: var(--v-btnPrimary-base) !important;
}

.candidate-table ::v-deep .v-icon.mdi-checkbox-marked.theme--light {
  color: var(--v-btnPrimary-base) !important;
}
