@import '../../../../content/scss/variables.scss';

#teamStemdoers {
  background-color: white;
  display: flex;
  height: 100%;
  cursor: default;

  .teamSelecterStemdoers {
    flex-direction: column;
    display: flex;
  }

  .checkout {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .checkout-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .checkout-totalCost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-bottom: 24px;
  }

  .checkout-buttons {
    width: 95%;
    display: flex;
    flex-direction: column;
  }

  .totalCost-mini {
    min-height: 76px;
    min-width: 181px;
    width: 50%;
    padding: 17px 16px 14px 16px;
    border-radius: 4px;
    border: 1px solid $lightGreyStemdo;

    span {
      color: $darkGreyStemdo;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    h4 {
      color: $blackStemdo;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }
  }

  .checkout-view {
    display: flex;
    background-color: white;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: 16px;
    padding: 24px 16px 16px 32px;
  }

  .checkout-team {
    overflow-y: auto;
    width: 100%;
    padding-right: 24px;
    max-width: 1920px;
  }

  .checkout-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 443px;
    min-width: 443px;
  }

  .checkout-chart {
    margin: 64px 0px 32px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 206px;

    .title-span {
      color: $blackStemdo;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      padding-bottom: 3px;
    }
  }

  .dateContainer {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: bold;
    margin-left: 5em;

    input {
      margin-left: 0.7em;
    }
  }

  .toolBoxTop {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $lightGreyStemdo;
    padding: 32px 28px 21px 28px;

    span {
      color: $blackStemdo;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    .btnPDF {
      width: 19px;
      height: 19px;
      flex-shrink: 0;
      transition: 0.6s;
      margin-left: 6px;
      box-shadow: none;

      .iconPDF {
        transform: scale(0.9);
        transition: 0.3s;
      }
    }

    .btnPDF:hover .iconPDF {
      transform: scale(0.9);
      transition: 0.3s;
    }

    button:hover {
      transition: 0.8s;
    }

    button:active {
      transform: translate(0em, 0.7em);
    }
  }

  .date-container {
    padding-left: 7%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border: 1px solid $lightGreyStemdo;

    p {
      margin: 9px 0px 3px 0px;
      font-weight: bold;
      font-size: 0.9em;
    }
  }

  .date-item {
    text-align: center;
  }

  .init-date,
  .end-date {
    width: 75%;
    box-sizing: border-box;
  }

  .proposal-label {
    .label {
      margin-bottom: -0.2em;
      margin-left: 0.3em;
      background-color: $greenStemdo;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: $whiteStemdo;
      font-size: 16px;
      padding: 0em 0.5em 0em 0.5em;
    }
  }

  .totalCost {
    min-height: 82px;
    min-width: 378px;
    width: 95%;
    padding: 17px 0 14px 16px;
    border-radius: 4px;
    border: 1px solid $lightGreyStemdo;
    margin-bottom: 16px;

    span {
      color: $darkGreyStemdo;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h2 {
      color: $blackStemdo;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  .jobDescription {
    margin: 1em 0em;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .toolBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .holdTeam {
      max-width: 200px;
      margin-top: 0.75em;
      transition: 0.5s;
      cursor: pointer;
      display: flex;
      background-color: $whiteStemdo;
      color: $blackStemdo;
      border-radius: 8px;
      padding: 0.46em 1em 0.46em 1em;
      transition: 0.5s;
      box-shadow: 1px 1px 4px #6d57bb65, -1px -1px 6px #8680da75;
      text-decoration: none;

      a {
        text-decoration: underline none;
      }

      span {
        text-decoration: underline none;
        padding-right: 0.3em;
      }

      svg {
        padding: 0.5em;
        transform: rotate(45deg);
        color: $whiteStemdo;

        margin-bottom: -0.05em;
        transition: 0.4s;
        padding-left: 0.2em;
      }
    }
  }

  .svgContainer {
    display: inline-block;
    border-radius: 4px;
    padding-left: 0.03em;
    text-align: center;
    width: 1.7em;
    height: 1.6em;
    background-color: $blueStemdo;

    svg {
      stroke-width: 10px;
    }
  }

  .holdTeam:hover {
    transition: 0.5s;
    color: $blackStemdo;
    background: $whiteStemdo;
    box-shadow: 2px 2px 4px #6d57bb65, -2px -2px 6px #8680da75;

    a {
      text-decoration: underline none;
    }

    span {
      text-decoration: underline none;
    }

    svg {
      transition: 0.4s;
      transform: rotate(0deg);
      transform: translateY(-2px);
    }
  }

  .circle-container {
    width: 135px;
    height: 135px;
    padding: 0px 12px;
  }

  .proposal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 16px 16px 16px;

    span {
      color: $blackStemdo;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: $blackStemdo;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }
  }

  .input-form-stemdo {
    background-color: white;
    border: 1px solid $lightGreyStemdo;
    display: block;
    width: 100%;
    height: 45px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: $blackStemdo;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .input-form-stemdo:focus {
    color: $blackStemdo;
    outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);

    &::placeholder {
      color: $greyStemdo;
    }
  }

  .checkoutError {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: 60px 60px 0px 0px;
    width: fit-content;
    min-width: 33%;
  }
}
